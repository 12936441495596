.horizontal-timeline {
  position: relative;
  display: flex;
  overflow-x: auto;
  padding-bottom: 1rem;

  &:before {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed $gray-500;
    content: ' ';
    position: absolute;
    top: 11%;
  }

  &--indicator {
    width: 1rem;
    height: 1rem;
    z-index: 20;
    position: relative;
    border-radius: 50%;
    background-color: $app-primary;
    margin: auto;
  }

  &--item {
    cursor: pointer;
    padding: 0 1rem;

    &.active {
      color: $app-primary;
    }
  }
}
