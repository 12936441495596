.label-shrink {
  transform: translate(0, 1.5px) scale(0.90) !important
}

.select-input {
  padding-bottom: 0 !important;
}

.react-select__value-container > div {
  margin: 0;
}

.dialog-form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}
