.ReactTable.app-table {
  .rt-thead .rt-th,
  .rt-thead .rt-td {
    padding: 12px 5px !important;
    z-index: 0 !important;
  }

  .rt-thead {
    .rt-th {
      height: 41px;

      &.-sort-desc,
      &.-sort-asc {
        box-shadow: none;
      }

      &.-sort-desc > div:first-child::after {
        content: "\25BC";
        color: rgba(0,0,0,0.4);
        margin-left: 5px;
      }

      &.-sort-asc > div:first-child::after {
        content: "\25B2";
        color: rgba(0,0,0,0.4);
        margin-left: 5px;
      }
    }
  }

  .rt-tbody .rt-td {
    display: flex;
    align-items: center;
    border-right-color: rgba(0, 0, 0, 0.05);
  }

  .-pagination .-pageJump input {
    border: none;
    background-color: unset;
    width: 50px !important;
  }

  .rt-noData {
    border-top: 1px solid rgba(0,0,0,0.05);
    position: relative;
    padding: 15px 10px;
    left: 0;
    transform: initial;
    text-align: center;
  }
}
