.app-loading {
  position: fixed !important;
  z-index: 6000;
  left: 50%;
  top: 50%;

  &:before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
  }
}