.app-container.fixed-drawer .app-main-header,
.app-container.mini-drawer .app-main-header{
  z-index: 1300 !important;
  left: 0;
}

.side-nav {
  padding-top: $app-bar-height + 20px;
}

.app-main-container {
  padding-top: $app-bar-height;
}

.text-decoration-none:hover {
  text-decoration: none !important;
}
